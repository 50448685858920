import { equal, getObjectKeys, uniqueArray } from '@avvoka/shared'
import { findInArrayByStrictEqual } from '../question_helpers'

export const getSaveEntryDocumentID = (
  documentsByAttributes: Record<string, number[]>,
  loopIdsByDoc: Backend.Questionnaire.RepeaterIDsWithDocumentIds,
  defaultDocumentID: number,
  att: string,
  repeaterId: Backend.Questionnaire.RepeaterID | undefined
) => {
  let documentID
  const attDocumentIds = documentsByAttributes[att]
  if (repeaterId) {
    const loopDocumentIds = findInArrayByStrictEqual(loopIdsByDoc, 'repeater_id', repeaterId)?.document_ids
    if (!loopDocumentIds)
      throw new Error(`No loop document ids found for repeater ${repeaterId}`)
    const loopDocumentId = attDocumentIds
      ? loopDocumentIds.find((id) => attDocumentIds.includes(id))
      : loopDocumentIds[0]
    documentID = loopDocumentId
  } else {
    documentID = attDocumentIds ? attDocumentIds[0] : defaultDocumentID
  }
  return documentID ?? defaultDocumentID
}

export const uniqueSharedRepeaters = (
  repeater: Backend.Questionnaire.RepeaterID,
  loopIdsByAttsFromDocuments: Record<string, Backend.Questionnaire.RepeaterID[]>
) => {
  if (!loopIdsByAttsFromDocuments) return []
  const sharedRepeaters = Object.values(loopIdsByAttsFromDocuments).reduce(
    (acc, val) => {
      if (val.some((repeaterId) => equal(repeaterId, repeater))) acc.push(...val)
      return acc
    },
    []
  )
  return uniqueArray(sharedRepeaters)
}

export const setIterationDocumentID = (
  loopIdsByDoc: Backend.Questionnaire.RepeaterIDsWithDocumentIds,
  repeaterId: Backend.Questionnaire.RepeaterID,
  defaultDocumentID: number
) => {
  const documentIDs = findInArrayByStrictEqual(loopIdsByDoc, 'repeater_id', repeaterId)?.document_ids
  let documentID
  if (documentIDs && documentIDs.length) documentID = documentIDs[0]
  return documentID ?? defaultDocumentID
}

export const applyEntries = (
  currentEntries: Backend.Questionnaire.Entries,
  newEntries: Backend.Questionnaire.Entries,
  changedAttributes: string[],
  force = false
) => {
  if (force) return newEntries
  const allAnswerKeys = getObjectKeys(newEntries).concat(getObjectKeys(currentEntries))
  return allAnswerKeys.reduce((acc, key) => {
    if (changedAttributes.includes(key)) {
      acc[key] = currentEntries[key]
    } else {
      acc[key] = newEntries[key]
    }

    return acc
  }, {} as Backend.Questionnaire.Entries)
}