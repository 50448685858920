import { clone, equal } from "@avvoka/shared"

/**
 * 
 * @param questions 
 * @param att 
 * @returns 
 */
export function isRepeatedAttribute (questions: Backend.Questionnaire.IQuestion[], att: string) {
  const question = getQuestionForAttribute(questions, att)

  if (question) {
    return isRepeatedQuestion(question)
  } else {
    return true
  }
}

export function findInArrayByStrictEqual <T, TProperty extends keyof T>(array: T[], key: TProperty, value: T[TProperty]) {
  return array?.find((item) => equal(item[key], value))
}

export function findIndexInArrayByStrictEqual <T, TProperty extends keyof T>(array: T[], key: TProperty, value: T[TProperty]) {
  return array?.findIndex((item) => equal(item[key], value))
}

/**
 * 
 * @param question 
 * @returns 
 */
export function isRepeatedQuestion (question: Backend.Questionnaire.IQuestion | undefined) {
  return !!question?.opts?.["repeater-id"]
}

export function getRepeaterIdForAttribute (questions: Backend.Questionnaire.IQuestion[], att: string) {
  const question = getQuestionForAttribute(questions, att)
  return question?.opts?.["repeater-id"]
}

export function prepareSuccessionForAttribute (questions: Backend.Questionnaire.IQuestion[], att: string, succession: Backend.Questionnaire.Succession) {
  const repeaterId = getRepeaterIdForAttribute(questions, att)
  if (repeaterId) {
    return Array.from({ length: repeaterId.length }, (_value, index) => succession[index] ?? 0)
  } else {
    return []
  }
}

export function areRepeatersEqual (r1: Backend.Questionnaire.RepeaterID | undefined, r2: Backend.Questionnaire.RepeaterID | undefined, depth: number) {
  if (typeof r1 === 'undefined' || typeof r1 === 'undefined') return r1 === r2
  else if (r1 && r2) {
    for (let i = 0; i < depth; i++) {
      if (r1[i] !== r2[i]) return false
    }

    return true
  } else {
    return false
  }
}

export function toRepeaterIdWithSuccession (repeaterId: Backend.Questionnaire.RepeaterID, succession: Backend.Questionnaire.Succession): Backend.Questionnaire.RepeaterIDWithSuccession {
  const repeaterIdWithSuccession = Array.from({ length: 2 * repeaterId.length - 1 })
  for (let i = 0; i < repeaterId.length; i++) {
    repeaterIdWithSuccession[i * 2] = repeaterId[i]
    if (i !== repeaterId.length - 1) {
      repeaterIdWithSuccession[i * 2 + 1] = succession[i] ?? 0
    }
  }

  return repeaterIdWithSuccession as Backend.Questionnaire.RepeaterIDWithSuccession
}

export function splitRepeaterIdWithSuccession (repeaterIdWithSuccession: Backend.Questionnaire.RepeaterIDWithSuccession) {
  const repeaterId = []
  const succession = []

  for (let i = 0; i < repeaterIdWithSuccession.length; i++) {
    if (i % 2 === 0) {
      repeaterId.push(repeaterIdWithSuccession[i])
    } else {
      succession.push(repeaterIdWithSuccession[i])
    }
  }

  return [repeaterId, succession] as [Backend.Questionnaire.RepeaterID, Backend.Questionnaire.Succession]
}

export function setLoopCount (questions: Backend.Questionnaire.IQuestion[], loopCounts: Backend.Questionnaire.LoopCounts, repeaterId: Backend.Questionnaire.RepeaterIDWithSuccession, count: number) {
  const setLoopCount = (repeaterId: Backend.Questionnaire.RepeaterIDWithSuccession, count: number) => {
    console.log(`SETTING ${repeaterId} TO ${count}`)

    const index = findIndexInArrayByStrictEqual(loopCounts, 'repeater_id', repeaterId)
    if (index === -1) {
      loopCounts.push({
        repeater_id: repeaterId,
        count
      })

      return 0
    } else {
      const previousCount = loopCounts[index].count

      loopCounts[index] = {
        repeater_id: repeaterId,
        count
      }

      return previousCount
    }
  }
  
  // Set specific loop count
  const previousCount = setLoopCount(repeaterId, count)

  const [repeaterIdWithoutSuccession, succession] = splitRepeaterIdWithSuccession(repeaterId)

  const descendantRepeaterIdsWithoutSuccession = questions.map((question) => question.opts['repeater-master-id'] || question.opts["repeater-id"]).filter(
    (questionRepeaterId) => areRepeatersEqual(
      repeaterIdWithoutSuccession,
      questionRepeaterId,
      repeaterIdWithoutSuccession.length
    ) && !equal(
      repeaterIdWithoutSuccession,
      questionRepeaterId
    )
  )

  // Add descendants in each iteration
  for (let i = previousCount; i < count; i++) {
    // Each descendant (except self)
    for (const descendant of descendantRepeaterIdsWithoutSuccession) {
      // Set loopcount to 0
      setLoopCount(
        toRepeaterIdWithSuccession(
          descendant,
          [...succession, i]
        ),
        1
      )
    }
  }

  // Drop descendants in each iteration
  for (let i = count + 1; i < previousCount; i++) {
    // Each existing loop count
    for (let j = 0; j < loopCounts.length; j++) {
      // Repeater matches
      if (
        areRepeatersEqual(
          [...repeaterId, i] as Backend.Questionnaire.RepeaterID,
          loopCounts[j].repeater_id as Backend.Questionnaire.RepeaterID,
          repeaterId.length + 1
        )
      ) {
        console.log(`DROPPING ${loopCounts[j].repeater_id} WITH ${loopCounts[j].count}`)

        loopCounts.splice(j--, 1)
      }
    }
  }
}

export function matchesRepeaterIdWithSuccession (repeaterIdWithSuccession: Backend.Questionnaire.RepeaterIDWithSuccession, repeaterId: Backend.Questionnaire.RepeaterID) {
  return repeaterIdWithSuccession.length === 2 * repeaterId.length - 1 && repeaterId.every((string, index) => repeaterIdWithSuccession[index * 2] === string)
}

export async function forEachSuccessionInLoopCount (questions: Backend.Questionnaire.IQuestion[], loopCounts: Backend.Questionnaire.LoopCounts, att: string, callback: (succession: Backend.Questionnaire.Succession) => Promise<void>) {
  const repeaterId = getRepeaterIdForAttribute(questions, att)
  if (repeaterId) {
    for (const loopCount of loopCounts) {
      if (matchesRepeaterIdWithSuccession(loopCount.repeater_id, repeaterId)) {
        const [, succession] = splitRepeaterIdWithSuccession(loopCount.repeater_id)
        for (let i = 0; i < loopCount.count; i++) {
          await callback([...succession, i])
        }
      }
    }
  } else {
    await callback([1])
  }
}

export function estimateLoopCountsForAttribute (questions: Backend.Questionnaire.IQuestion[], entries: Backend.Questionnaire.Entries, att: string) {
  const loopCounts: Backend.Questionnaire.LoopCounts = []

  const repeaterId = getRepeaterIdForAttribute(questions, att)
  if (repeaterId && entries[att] && entries[att].length > 0) {
    const process = (repeaterId: Backend.Questionnaire.RepeaterID, succession: Backend.Questionnaire.Succession) => {
      const count = Math.max(0, ...entries[att].filter((entry) => succession.every((succ, j) => entry.succession[j] === succ)).map((entry) => entry.succession[succession.length])) + 1
 
      loopCounts.push({
        repeater_id: toRepeaterIdWithSuccession(repeaterId.slice(0, succession.length + 1), succession),
        count
      })

      if (repeaterId.length > succession.length + 1) {
        for (let i = 0; i < count; i++) {
          process(repeaterId, [...succession, i])
        }
      }
    }

    process(repeaterId, [])
  }

  return loopCounts
}

/**
 * 
 * @param questions 
 * @param att 
 * @returns 
 */
export function getQuestionForAttribute (questions: Backend.Questionnaire.IQuestion[], att: string) {
  return questions.find((question) => question.att === att)
}

/**
 * 
 * @param questions 
 * @param entries 
 * @param att 
 * @param succession 
 * @returns 
 */
export function getEntry (questions: Backend.Questionnaire.IQuestion[], entries: Backend.Questionnaire.Entries, att: string, succession: Backend.Questionnaire.Succession): Backend.Questionnaire.Entry | undefined {
  const entrySuccession = prepareSuccessionForAttribute(questions, att, succession)

  return findInArrayByStrictEqual(entries[att], 'succession', entrySuccession)
}

/**
 * 
 * @param questions 
 * @param entries 
 * @param att 
 * @param succession 
 * @returns 
 */
export function getEntryOrDefault (questions: Backend.Questionnaire.IQuestion[], entries: Backend.Questionnaire.Entries, att: string, succession: Backend.Questionnaire.Succession): Backend.Questionnaire.Entry | undefined {
  const entry = getEntry(questions, entries, att, succession)
  if (entry) {
    return entry
  }

  const question = getQuestionForAttribute(questions, att)
  const entrySuccession = isRepeatedAttribute(questions, att) ? succession : []

  if (question && (question.opts.default || question.opts.modificator)) {
    return {
      succession: entrySuccession,
      value: question.opts.default || '',
      modificator: question.opts.modificator || ''
    }
  }
}

/**
 * 
 * @param entries 
 * @param att 
 * @returns 
 */
export function hasEntryForAttribute (entries: Backend.Questionnaire.Entries, att: string) {
  return entries[att] ? entries[att].length > 0 : false
}

export function addEntryIdentifier (identifiers: Backend.Questionnaire.EntryIdentifier[], identifier: Backend.Questionnaire.EntryIdentifier) {
  const index = identifiers.findIndex((_identifier) => _identifier.att === identifier.att && equal(_identifier.succession, identifier.succession))
  if (index === -1) {
    identifiers.push({ ...identifier })
  }
}

export function removeEntryIdentifier (identifiers: Backend.Questionnaire.EntryIdentifier[], identifier: Backend.Questionnaire.EntryIdentifier) {
  const index = identifiers.findIndex((_identifier) => _identifier.att === identifier.att && equal(_identifier.succession, identifier.succession))
  if (index !== -1) {
    identifiers.splice(index, 1)
  }
}

/**
 * Sets entry
 * @param questions 
 * @param entries 
 * @param att 
 * @param entry 
 * @returns 
 */
export function setEntry (questions: Backend.Questionnaire.IQuestion[], entries: Backend.Questionnaire.Entries, att: string, entry: Backend.Questionnaire.Entry): { entry: Backend.Questionnaire.Entry, change: boolean } {
  entries[att] ||= []

  // Set succession to 0 if entry is not repeated
  const entrySuccession = isRepeatedAttribute(questions, att) ? entry.succession : []
  const entryNormalized = { ...entry, succession: entrySuccession }

  // Check if entry exists in the entries array
  const entryIndex = findIndexInArrayByStrictEqual(entries[att], 'succession', entrySuccession)
  if (entryIndex === -1) {
    // If entry doesnt exist, push it
    entries[att].push(entryNormalized)

    return {
      entry: entryNormalized,
      change: true
    }
  } else {
    const { value: existingValue, modificator: existingModificator } = entries[att][entryIndex]

    // Check if entry changed
    const entryEqual = equal(entry.value, existingValue) && entry.modificator == existingModificator
    if (entryEqual) {
      // If not, then return the original entry
      return {
        entry: entries[att][entryIndex],
        change: false
      }
    } else {
      return {
        entry: (entries[att][entryIndex] = entryNormalized),
        change: true
      }
    }
  }
}

export function unsetEntry (entries: Backend.Questionnaire.Entries, att: string, succession: Backend.Questionnaire.Succession) {
  const entryIndex = findIndexInArrayByStrictEqual(entries[att], 'succession', succession) ?? -1
  if (entryIndex !== -1) {
    entries[att].splice(entryIndex)

    if (entries[att].length === 0) {
      delete entries[att]
    }

    return true
  } else {
    return false
  }
}

/**
 * Splits value by delimiter
 * @param value Value
 * @param delimiter Delimiter
 * @returns Array of segments, or empty array if value is blank
 */
export function splitEntryValue (value: string | undefined, delimiter: string) {
  return value ? value.split(delimiter) : []
}