import { handleEditorStyles } from './editor/styles'
import Utils from './utils'
import consumer from '../channels/consumer'
import { DOMEmit } from './dom_utils'
import type {
  IQuestionnaireStore,
  QStoreContext,
  SmartStore
} from './QuestionnaireUtils'
import {
  getSaveEntryDocumentID,
  uniqueSharedRepeaters
} from './template_packs/save_entry_utils'
import { getActivePinia } from 'pinia'
import { compareValues } from './compare_values/compare_values'
import { getStateFromBoolean } from './questionnaire/sidekiq_states'
import { areRepeatersEqual, getEntry } from './question_helpers'
import { useDocumentStore } from '~/stores/generic/document.store'
import { useErrorToast, useToast } from '@component-utils/toasts'
import { equal, uniqueArray } from '@avvoka/shared'
import AvvParser from './parser'

const axios = Utils.axios

interface IChangedPartyNames {
  newPartyName: string
  oldPartyName?: string
}

export const UNALLOCATED_QUESTIONNAIRE = 'Unallocated'

export const findChangedParty = (
  oldPartiesNames: string[],
  newPartiesNames: string[]
): string | null | IChangedPartyNames | undefined => {
  if (oldPartiesNames.length < newPartiesNames.length) {
    const addedPartyName = newPartiesNames.find(
      (partyName) => oldPartiesNames.indexOf(partyName) === -1
    )
    return addedPartyName
  } else {
    const newPartyName = newPartiesNames.find(
      (partyName) => oldPartiesNames.indexOf(partyName) === -1
    )
    if (newPartyName == null) return null
    const oldPartyName = oldPartiesNames.find(
      (partyName) => newPartiesNames.indexOf(partyName) === -1
    )
    return { newPartyName, oldPartyName }
  }
}

export function deleteQuestionnaire(
  store: QStoreContext,
  partyToRealocate: string
) {
  store.commit('DELETE_QUESTIONNAIRE', store.state.selected_party)
  if (partyToRealocate) {
    const questions = store.state.questions.filter(
      (q: Backend.Questionnaire.IQuestion) =>
        q.party === store.state.selected_party
    )
    questions.forEach((q) => {
      store.commit('REPLACE_QUESTION', {
        oldQuestion: q,
        newQuestion: { ...q, party: partyToRealocate }
      })
    })
  }
}

export const saveEntry = (
  {
    state,
    dispatch,
    commit
  }: { state: IQuestionnaireStore; dispatch: any; commit: any },
  att: string,
  entry: Backend.Questionnaire.Entry
) => {
  const question = state.questions.find(
    (q: Backend.Questionnaire.IQuestion) => q.att === att
  )
  if (!question) {
    dispatch('save_entry', { att, start: false, succession: entry.succession })
    return
  }
  const data: Record<string, any> = {
    value: entry.value,
    modificator: entry.modificator,
    type: question.type,
    entry_name: window.AvvParser.unicode(att),
    send_agreement: state.show_editor,
    id: state.document_id
  }

  const repeaterId = question.opts['repeater-id']
  const isCurrency = question.type === 'currency'
  const isPhone = question.type === 'phone'

  if (state.created_from_pack) {
    data.id = getSaveEntryDocumentID(
      AvvStore.state.documents_by_attributes,
      AvvStore.state.loopIdsByDoc,
      state.document_id,
      att,
      repeaterId
    )
  }

  if (repeaterId) {
    data.isRepeater = true
    data.repeater = repeaterId
    data.succession = entry.succession
  }

  const url = AvvStore.state.save_entry_url || '/documents/save_entry'
  const previouslySavedValue = getEntry(state.questions, state.last_saved_entries, att, entry.succession)
  const isReset = typeof previouslySavedValue === 'undefined' && data.value === ''

  if (
    compareValues(previouslySavedValue, entry, 'dq') &&
    !isCurrency &&
    !isPhone &&
    !isReset
  ) {
    dispatch('save_entry', {
      att,
      start: false,
      succession: entry.succession
    })
    return
  }
  void axios
    .post<{
      questionnaire_data: Backend.Questionnaire.IQuestionnaireData
      questionnaire_locked: boolean
    }>(url, data)
    .then(async (result) => {
      await dispatch('save_entry', { att, start: false, succession: entry.succession })
      commit('SET_QUESTIONNAIRE_DATA', result.data.questionnaire_data)
      if ('questionnaire_locked' in result.data)
        commit('SET_SIDEKIQ_STATUS', {
          sidekiq_status: getStateFromBoolean(result.data.questionnaire_locked),
          immediate: true
        })
      commit('ADD_LAST_SAVED_ENTRY', {
        att,
        entry
      })
      DOMEmit('agreement_html:update', {
        html: result.data.agreement_html,
        documentID: data.id
      })
      if (!AvvStore.state.is_doc_negotiable && !AvvStore.state.is_forms && state.show_editor) {
        const wrapper = document.querySelector<HTMLElement>(
          `#q-editor-${data.id} .avv-container`
        )
        if (!wrapper) return
        handleEditorStyles(useDocumentStore(getActivePinia()), wrapper)
      }
      return
    })
}

export const getCurrentQuestionnaireData = async (): Promise<{
  valid_conditions: Backend.Questionnaire.ValidConditions
  loop_counts: Backend.Questionnaire.LoopCounts
}> => {
  const document_id = AvvStore.state.document_id
  const url =
    AvvStore.state.questionnaire_data_url ||
    `/documents/${document_id}/questionnaire_data`
  const response = await axios.get(url)
  return response.data.questionnaire_data
}

export const isFromRepeater = (q: Backend.Questionnaire.IQuestion, repeater: Backend.Questionnaire.RepeaterID | undefined, depth: number) => {
  if (!repeater || !q) return false
  return (['repeater-id', 'repeater-master-id'] as const).some((key) => areRepeatersEqual(q.opts[key], repeater, depth))
}

const shouldMerge = (q1: Backend.Questionnaire.IQuestion, q2: Backend.Questionnaire.IQuestion, store: SmartStore<IQuestionnaireStore>, layer: number) => {
  if (q1.opts['repeater-merge'] || q2.opts['repeater-merge']) return true
  const masterID = q1.opts['repeater-master-id'] ?? q2.opts['repeater-master-id']
  const masterQuestion = store.state.questions.find((q) => areRepeatersEqual(q.opts['repeater-id'], masterID, layer))
  if (masterQuestion) return masterQuestion.opts['repeater-merge']
  return false
}

export const areInSameRenderedRepeater = (q1: Backend.Questionnaire.IQuestion, q2: Backend.Questionnaire.IQuestion, store: SmartStore<IQuestionnaireStore>, layer: number) => {
  if (shouldMerge(q1, q2, store, layer))
    return (
      isFromRepeater(q2, q1.opts['repeater-id'], layer) ||
      isFromRepeater(q2, q1.opts['repeater-master-id'], layer)
    ) || (
      store.state.created_from_pack && uniqueSharedRepeaters(q1.opts['repeater-id']!, AvvStore.state.loopIdsByAttsFromDocuments).some((repeaterId) => areRepeatersEqual(repeaterId, q2.opts['repeater-id'], layer))
    )
  else
    return (
      areRepeatersEqual(q2.opts['repeater-id'], q1.opts['repeater-id'], layer) ||
      uniqueSharedRepeaters(q2.opts['repeater-id']!.slice(0, layer), AvvStore.state.loopIdsByAttsFromDocuments).some((repeaterId) => areRepeatersEqual(repeaterId, q1.opts['repeater-id'], layer))
    )
}

export const listenOnStatusChanges = (
  store: SmartStore<IQuestionnaireStore>,
  id?: string
) => {
  const documentID = id ? id : String(store.state.document_id)
  consumer.subscriptions.create(
    { channel: 'DocumentChannel', id: documentID },
    {
      received(response: { type: string; data: any }) {
        switch (response.type) {
          case 'questionnaire_locked':
            store.commit('SET_SIDEKIQ_STATUS', {
              sidekiq_status: getStateFromBoolean(response.data),
              immediate: false
            })
            break
          case 'questionnaire_data':
            store.commit('SET_QUESTIONNAIRE_DATA', response.data)
            break
          case 'question_update':
            store.commit('SET_QUESTION_OPTIONS', response.data)
            break
        }
      }
    }
  )
}

export const prepareLoopCounts = (
  force = false,
  stateLoopCounts: Backend.Questionnaire.LoopCounts,
  newLoopCounts: Backend.Questionnaire.LoopCounts
) => {
  if (force) return newLoopCounts
  else return uniqueArray([newLoopCounts, stateLoopCounts].flat(), (loopCount) => loopCount.repeater_id)
}

export const parseMatch = (match: string, wrapInAst = true) => {
  const matchForParse = match.slice(1, -1)
  const superAst = Ast.parse(matchForParse, wrapInAst)
  if (!superAst) return null
  const ast = 'ast' in superAst ? superAst.ast : superAst
  const [value, defaultValue] = ast['Att_default'] as [string, string]
  return [
    AvvParser.decode(value),
    AvvParser.decode(defaultValue)
  ]
}

export const deleteAttachment = async (id: number) => {
  const documentID = AvvStore.state.document_id
  const data = await axios.delete(`/attachments/${id}.json?document_id=${documentID}`)
  if (data.status === 200) {
    AvvStore.commit('REMOVE_ATTACHMENT', id)
    avv_dialog({
      snackMessage: localizeText(
        'questionnaire.file_upload.attachment_deleted'
      ),
      snackStyle: 'success'
    })
    return data.status
  } else {
    avv_dialog({ snackMessage: 'Something went wrong', snackStyle: 'error' })
    return data.status
  }
}

export const renameAttachment = async (id: Backend.Models.Attachment['id'], file_file_name: Backend.Models.Attachment['file_file_name']) => {
  try {
    // No return value check needed as axios throws exception when the status code isnt 2XX
    await axios.put(`/attachments/${id}.json`, {
      document_id: AvvStore.state.document_id,
      file_file_name
    })

    useToast({ message: 'Attachment was successfully renamed' })

    return true
  } catch (e) {
    useErrorToast('Something went wrong')

    return false
  }
}

export const partyCheck = (attachment: Backend.Models.Attachment) => {
  const creatorUserID = attachment.user_id
  const documentUsers = Object.values(AvvStore.state.users)
  const creatorData = documentUsers.find(
    (user) => user.user_id == creatorUserID
  )
  const creatorParty = creatorData ? creatorData.party_type : null
  const curentUserParty = AvvStore.state.active_participant?.party_type
  if (!curentUserParty || !creatorParty) {
    return false
  }
  return creatorParty == curentUserParty
}
